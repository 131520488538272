require('intersection-observer');

const config = {
    rootMargin: '0px 0px 30px 0px',
    threshold: 0
};

// register the config object with an instance
// of intersectionObserver
let observer = new IntersectionObserver(function (entries, self) {
    // iterate over each entry
    entries.forEach(entry => {
        // process just the images that are intersecting.
        // isIntersecting is a property exposed by the interface
        if (entry.isIntersecting) {
            setTimeout(() => {
                entry.target.src = entry.target.getAttribute('data-src')
                entry.target.setAttribute('data-src', '');
            }, 200);
            // the image is now in place, stop watching
            self.unobserve(entry.target);
        }
    });
}, config);

const imgs = document.querySelectorAll('[data-src]');
imgs.forEach(img => {
    observer.observe(img);
});