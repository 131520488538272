import { TezosToolkit } from '@taquito/taquito';
import { BeaconWallet } from '@taquito/beacon-wallet';

require('./countdown.js');
require('./img.js');

const isProd = true;

const contract = isProd ? 'KT1AvxTNETj3U4b3wKYxkX6CKya1EgLZezv8' : 'KT1MzhHfCvqrwfksksrYWxDfE4YLZmtedDpY';

const mainnetNodes = [
    'https://mainnet.api.tez.ie',
    'https://mainnet.smartpy.io',
    'https://teznode.letzbake.com',
    'https://eu01-node.teztools.net/',
    'https://eu01-node.teztools.net/',
    'https://mainnet-node.madfish.solutions',
];
const testnetNodes = ['https://granadanet.api.tez.ie'];
const nodes = isProd ? mainnetNodes : testnetNodes;
const network = isProd ? 'mainnet' : 'granadanet';
let adopted = 0;

let wallet = new BeaconWallet({
    name: 'COMMON_SKELE',
});
const Tezos = new TezosToolkit(nodes[Math.floor(Math.random() * nodes.length)]);
Tezos.setWalletProvider(wallet);

const $walletId = document.getElementById("wallet-id");
const $unsyncButton = document.getElementById("unsync");

const $mintButton1 = document.getElementById("mint-button1");
const $mintButton3 = document.getElementById("mint-button3");
const $mintButton5 = document.getElementById("mint-button5");
const $viewOnObjktButton = document.getElementById("view-on-objkt-button");
const $loader = document.getElementById("loader");

document.addEventListener('DOMContentLoaded', async (event) => {
    $mintButton1.onclick = (e) => {
        mint(e, 1);
    };
    $mintButton3.onclick = (e) => {
        mint(e, 3);
    };
    $mintButton5.onclick = (e) => {
        mint(e, 5);
    };

    $viewOnObjktButton.onclick = (e) => {

        window.location.href = 'https://objkt.com/collection/rcs'
    };

    $unsyncButton.onclick = (e) => {
        unsyncWallet(e);
    };

    updateSyncButton();
});

async function mint(e, n) {
    try {
        $loader.children[0].children[0].innerHTML = 'Loading...';
        $loader.style.display = 'flex';
        e.preventDefault();

        if (!hasPermission()) {
            wallet.disconnect();
            await syncWallet(e);
        }


        if (hasPermission()) {
            const op = await (await Tezos.wallet.at(contract)).methods.buy(n).send({
                amount: n * 5,
                storageLimit: 1000,
            })

            $loader.children[0].children[0].innerHTML = 'Waiting for confirmation...';

            await op.confirmation(1);

            $loader.style.display = 'none';
        }
    } catch (e) {
        console.log(e);
        $loader.style.display = 'none';
    }

}

async function updateSyncButton() {
    if (hasPermission()) {
        $walletId.style.display = 'block';
        $unsyncButton.style.display = 'block';
        $walletId.innerHTML = await getAddressCropped();
    } else {
        $walletId.style.display = 'none';
        $unsyncButton.style.display = 'none';
    }
}

async function getAddressCropped() {
    const address = await wallet.getPKH();
    return `${address.substring(0, 5)}...${address.slice(-5)}`;
}

async function syncWallet(e) {
    e.preventDefault();

    if (!hasPermission()) {
        // The Beacon wallet requires an extra step to set up the network to connect to and the permissions:
        wallet = new BeaconWallet({
            name: 'COMMON_SKELE',
        });
        Tezos.setWalletProvider(wallet);
        await wallet.requestPermissions({
            network: {
                type: network,
            },
        });

        updateSyncButton();
    }

}


async function unsyncWallet(e) {
    e.preventDefault();

    // localStorage.removeItem('walletPermission');
    await wallet.disconnect();
    await wallet.clearActiveAccount();
    await wallet.clearActiveAccount();

    updateSyncButton();
}


function hasPermission() {
    return localStorage.getItem('beacon:active-account') && localStorage.getItem('beacon:active-account') !== 'undefined';
}
