import moment from 'moment-timezone';

const startDate = moment.tz('2021-11-01 21:00', 'Europe/Paris');
const hasCountdown = moment().isBefore(startDate);
const $countdown = document.getElementById('countdown-text');
const $windowClosed = document.getElementById('window-closed');
const $mintButtons = document.getElementById('mint-buttons');
const $viewOnObjkt = document.getElementById('view-on-objkt');

if (!hasCountdown) {
    $windowClosed.style.display = 'block';
    $viewOnObjkt.style.display = 'flex';
} else {
    $countdown.style.display = 'block';
    $mintButtons.style.display = 'flex';
}

setTime();
const countdownInterval = setInterval(setTime, 1000);

function setTime() {
    $countdown.textContent = hasCountdown ? getTimeLeft() : '';
}


function getTimeLeft() {
    const diff = moment.duration(moment(startDate).diff(moment()));
    const days = diff.days();
    const hours = diff.hours();
    const minutes = diff.minutes();
    const seconds = diff.seconds();

    return `${days.toString().padStart(2, 0)}:${hours.toString().padStart(2, 0)}:${minutes.toString().padStart(2, 0)}:${seconds.toString().padStart(2, 0)}`;
}